import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@acuity-brands/fusion';
import SitePicker from '../../components/sitePicker.js';
import SiteStatus from './components/siteStatus.js';
import ChartFilter from './components/chartFilter.js';
import DashboardDataGrid from './components/dashboardDataGrid.js';
import axios from 'axios';

const Dashboard = () => {
    // const [loading, setLoading] = useState(false);
    const [siteMenu, setSiteMenu] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null)
    const [site, setSite] = useState(null);
    const [devices, setDevices] = useState([]);
    const [timestamp, setTimeStamp] = useState('N/A');
    const [deviceTimeStamp, setDeviceTimeStamp] = useState('N/A');
    const [chartDisplay, setChartDisplay] = useState({ Electricity: true, Luminance: false, Temperature: false, Cost: false });

    const handleSiteSelect = (node) => {
        setSelectedSite(node);
    };

    const handleChartDisplayChange = (updatedChartDisplay) => {
        setChartDisplay(updatedChartDisplay);
    }

    const filterObject = (obj, index) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                var value = obj[key];
                if (typeof value === 'object' && value !== null) {
                    var innerKeys = Object.keys(value);
                    if (innerKeys.length > 1) {
                        obj[key] = value[innerKeys[1]];
                    }
                    else if (innerKeys.length === 1) {
                        obj[key] = null;
                    }
                }
            }
        }

        obj['id'] = index;
        return obj;
    };

    useEffect(() => {
        async function fetchSitesList() {
            const result = await getSiteInfo({ action: 'sites' });
            setSiteMenu(result);
            const defaultSite = await getSiteInfo({ site: result[0] });
            setSite(defaultSite);
            setTimeStamp(defaultSite['lastCountTimestamp'])
        }

        fetchSitesList();
    }, [])

    useEffect(() => {
        async function fetchSite() {
            const siteResult = await getSiteInfo({ site: selectedSite });
            setSite(siteResult);
            const deviceResult = await getSiteCatalog({ site: selectedSite });
            if (deviceResult) {
                const deviceFiltered = Object.keys(deviceResult)
                    .map((index) => filterObject(deviceResult[index], index));
                setDevices(deviceFiltered);
                setDeviceTimeStamp(deviceResult['timestamp']);
            }
            else {
                setDevices([])
            }
        }

        fetchSite();
    }, [selectedSite])

    const getSiteInfo = (params) => {
        return axios.get('https://smartsystems-8439f-prod0.azurewebsites.net/api/deviceStatus', {
            params,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_SITE_DEVICE_DB_API_KEY
            },
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    };

    const getSiteCatalog = (params) => {
        return axios.get('https://smartsystems-6475f-prod0.azurewebsites.net/api/getSiteCatalog', {
            params,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_MASTER_KEY
            },
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    };

    return (
        
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F2F2F2' }}>

            <Box sx={{ display: 'flex', height: 'auto', p: '20px', gap: '20px', flexGrow: 1 }}>
                <Box sx={{ width: '200px', backgroundColor: '#FFFFFF', border: '1px solid #e0e0e0', borderRadius: '4px', minWidth: '150px' }}>
                    {siteMenu.length !== 0 ? <SitePicker sites={siteMenu} onNodeSelect={handleSiteSelect} />
                        : <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    {/* <Box sx={{ border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#FFFFFF', flexGrow: 1, py: '15px', px: '20px' }}>
                        {site ? <SiteStatus site={site} timestamp={timestamp} chartDisplay={chartDisplay} handleChartDisplayChange={handleChartDisplayChange} />
                            : <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>}
                    </Box> */}
                    {/* <Box sx={{ border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#FFFFFF', mt: '20px' }}>
                        <ChartFilter devices={devices} chartDisplay={chartDisplay} />
                    </Box> */}
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1, minHeight: '330px', borderRadius: '4px', backgroundColor: '#FFFFFF', mx: '20px', mb: '20px' }}>
                <DashboardDataGrid devices={devices} site={site} deviceTimeStamp={deviceTimeStamp}/>
            </Box>
        </Box>
    )
}

export default Dashboard;