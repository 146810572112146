import Stepper from '../../components/stepper';
import StepperButtons from '../../components/stepperButtons';
import { Box, Typography } from '@acuity-brands/fusion';

const Onboard = () => {

  return (
    <>
      <Box sx={{ width: '100%', mt: '35px' }}>
        <Stepper step={0} />
      </Box>
      <Box sx={{ mx: 'auto', mt: '100px' }}>
          <Typography variant='h2'>Site Onboarding Checklist</Typography>
        </Box>
      <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 3, mb: '200px' }}>
        <a href="https://acuitybrandsinc-my.sharepoint.com/:b:/g/personal/tas30_acuitysso_com/EdOxDI6fycxMhqYl-BRuDFwBro0DK-A5QfkclyzsOrMONA?e=gwf43W" target="_blank" rel="noopener noreferrer">
          <Typography variant='h5'>
            • Atrius Connection Security Guide
          </Typography>
        </a>
        <a href="https://www.acuitybrands.com/api/products/getasset/ke2-therm/947902/3e4b6ace-af00-4cc5-a116-b6447eed228f/EM-Series-Bacnet-instructions-en.pdf?abl_version=11%2f27%2f2018+00%3a00%3a00&DOC_Type=Installation_Instruction_Sheets" target="_blank" rel="noopener noreferrer">
          <Typography variant='h5'>
            • KE2 Them Edge Manager (LTE)
          </Typography>
        </a>
        <a href="https://teams.microsoft.com/l/message/19:meeting_YjRmMDJlYzgtZDFjMy00ZTI1LWFjNjItMzg4YTgwZDgwNjc3@thread.v2/1710429026152?context=%7B%22contextType%22%3A%22chat%22%7D" target="_blank" rel="noopener noreferrer">
          <Typography variant='h5'>
            • Connection Troubleshooting Guide
          </Typography>
        </a>
      </Box>
      <StepperButtons navigateTo={'/onboard/discover'} backDisabled={true} />
    </>
  );
}

export default Onboard;
