import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Logo } from '@acuity-brands/fusion';
import atrius_full_light from '../imgs/atrius_full_light.svg'

const NavBar = () => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar elevation={2} sx={{ position: 'absolute', backgroundColor: 'white' }}>
                <Toolbar sx={{ ml: '74px' }}>
                    <Link component={RouterLink} to="/" underline="none" mr='auto'>
                        <Logo alt="Atrius logo" src={atrius_full_light} />
                    </Link>
                    <WelcomeName />
                    <SignInSignOutButton />
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default NavBar;