import React from 'react';
import { Box, Typography, TreeView, TreeItem } from '@acuity-brands/fusion';

const SitePicker = (props) => {
    const sites = props.sites;

    const handleNodeSelect = (event, nodeId) => {
        const [prefixIndex, siteIndex] = nodeId.split('.');
        const preIdx = parseInt(prefixIndex) - 1;
        const siteIdx = siteIndex ? (parseInt(siteIndex) - 1) : 0;
        const building = Object.keys(groupedSites)[preIdx];
        const num = groupedSites[building][siteIdx];
        const selectedSite = `${building}_${num}`;
        props.onNodeSelect(selectedSite);
    };

    const groupedSites = sites.reduce((acc, curr) => {
        const prefix = curr.split('_')[0];
        const site = curr.split('_')[1];
        if (!acc[prefix]) {
            acc[prefix] = [];
        }
        acc[prefix].push(site);
        return acc;
    }, {});

    const treeData = Object.entries(groupedSites).map(([prefix, sites], index) => ({
        label: `${prefix}`,
        nodeId: `${index + 1}`,
        children: sites.map((site, idx) => ({
            name: site,
            nodeId: `${index + 1}.${idx + 1}`,
            label: site
        }))
    }));

    const renderTree = (nodes) => (
        <TreeItem key={nodes.name} nodeId={nodes.nodeId} label={nodes.label}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid #e0e0e0', bgcolor: '#FFFFFF', py: '10px', borderRadius: '4px' }}>
                <Typography color='secondary'>Choose Site:</Typography>
            </Box>
            <Box sx={{ position: 'relative', overflowY: 'scroll' }}>
                <TreeView onNodeSelect={handleNodeSelect}>
                    <React.Fragment>
                        {treeData.map((node) => renderTree(node))}
                    </React.Fragment>
                </TreeView>
            </Box>
        </>
    )
}

export default SitePicker;