import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Typography, Box } from "@acuity-brands/fusion";

const WelcomeName = () => {
    const { instance } = useMsal();
    const [name, setName] = useState(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name);
        } else {
            setName(null);
        }
    }, [activeAccount]);

    if (name) {
        return (
            <Box sx={{mr: '15px'}}><Typography variant="body300">{name}</Typography></Box>
        )
    } else {
        return null;
    }
};

export default WelcomeName;