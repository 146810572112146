import Stepper from '../../components/stepper';
import StepperButtons from '../../components/stepperButtons';
import { Box, Typography } from '@acuity-brands/fusion';

const Create = () => {

  return (
    <>
      <Box sx={{ width: '100%', mt: '35px' }}>
        <Stepper step={2} />
      </Box>
      <Box sx={{width: '100%', flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography>
          main content create
        </Typography>
      </Box>
      <StepperButtons navigateBack={'/onboard/discover'} navigateTo={'/onboard/validate'}/>
    </>
  );
}

export default Create;
