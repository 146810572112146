// import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Box } from "@acuity-brands/fusion";
import { loginRequest } from "../authConfig";


export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Box>
            <Button
                onClick={() => instance.loginRedirect(loginRequest)}
                color="inherit"
                variant="outlined"
                aria-haspopup="true"
            >
                Login
            </Button>
        </Box>
    )
};