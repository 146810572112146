import { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import axios from "axios";

export default function DashboardDataGrid({ devices, site, deviceTimeStamp }) {
  const [rows, setRows] = useState(devices);
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const postLoadSetpoint = (data) => {
    return axios
      .post(
        "https://smartsystems-6475f-prod0.azurewebsites.net/api/loadSetpoint",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-functions-key": process.env.REACT_APP_MASTER_KEY
          },
        }
      )
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const processRowUpdate = (newRow) => {
    async function processLoadUpdate(data) {
      const result = await postLoadSetpoint(data);
      return result;
    }

    const updatedRow = { ...newRow, isNew: false };
    const data = {
      site: site.site,
      name: `${updatedRow["label"]}_${updatedRow["ID"]}`,
      value: updatedRow["Load Setpoint"],
    };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log(processLoadUpdate(data));

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    setRows(devices);
  }, [devices]);

  const cols = [
    {
      field: "ID",
      flex: 1,
      headerName: "ID",
      editable: false,
    },
    {
      field: "dtid",
      flex: 1,
      headerName: "DTID",
      editable: false,
    },
    {
        field: "model",
        flex: 1,
        headerName: "Model",
        editable: false,
    },
    {
        field: "label",
        flex: 1,
        headerName: "Label",
        editable: false,
    },
    {
      field: "Online Status",
      flex: 1,
      headerName: `Connection`,
      editable: false,
    },
    {
      field: "On Off Status",
      flex: 1,
      headerName: "On/Off Status",
      editable: false,
    },
    {
      field: "Luminance Setpoint",
      flex: 1,
      headerName: "Luminance Setpoint",
      editable: false,
    },
    {
      field: "Temperature Sensor",
      flex: 1,
      headerName: "Temp Sensor",
      editable: false,
    },
    {
      field: "RSSI Sensor",
      flex: 1,
      headerName: "RSSI",
      editable: false,
    },
    {
        field: "Max Load Setpoint",
        flex: 1,
        headerName: "High End Trim",
        editable: false,
    },
    {
        field: "Min Load Setpoint",
        flex: 1,
        headerName: "Low End Trim",
        editable: false,
    },
    {
      field: "Load Parameter",
      flex: 1,
      headerName: "Usage (Input Watts)",
      editable: false,
  },
  {
    field: "Load Setpoint",
    flex: 1,
    type: "number",
    headerName: "Max Output Watts",
    editable: true,
  },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      width: 10,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      columns={cols}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      loading={!devices}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      pageSizeOptions={[20, 50, 100]}
    />
  );
}
