import ChevronRight from '@acuity-brands/fusion/icons/chevron-right.js';
import ChevronLeft from '@acuity-brands/fusion/icons/chevron-left.js';
import { Box, Button } from '@acuity-brands/fusion';
import { useNavigate } from "react-router-dom";

const StepperButtons = ({ navigateBack, navigateTo, backDisabled, nextDisabled }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '50px', mt: 0 }}>
            {backDisabled ? <Box /> :
                <Button
                    onClick={() => { navigate(navigateBack) }}
                    startIcon={ChevronLeft}
                >
                    Back
                </Button>}
            {nextDisabled ? <Box /> :
                <Button
                    onClick={() => { navigate(navigateTo) }}
                    endIcon={ChevronRight}
                >
                    Next
                </Button>}
        </Box>
    )
}

export default StepperButtons;