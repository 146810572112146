import { Box, Typography, Button, Illustration } from '@acuity-brands/fusion';
import Logo from '../imgs/atrius_stacked.png';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const Login = () => {
    const { instance } = useMsal();

    return (
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#F2F2F2' }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', borderRadius: '4px',
                width: '400px', height: '500px', border: '1px solid #e0e0e0', backgroundColor: '#ffffff'
            }}>
                <Illustration alt='atrius logo' src={Logo} height={90} />
                <Typography variant='h5'>Welcome to Atrius Portal!</Typography>
                <Button
                    sx={{ width: '350px', height: '50px', mt: '50px' }}
                    onClick={() => instance.loginRedirect(loginRequest)}
                >
                    LOGIN
                </Button>
            </Box>

        </Box>
    )
}

export default Login;