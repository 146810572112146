import { Box, Typography, CircularProgress, Skeleton, IconButton } from '@acuity-brands/fusion';
import InfoIcon from '@acuity-brands/fusion/icons/info.js';
import { useState, useEffect } from 'react';
import StepperButtons from '../../components/stepperButtons';
import Stepper from '../../components/stepper';
import PieActiveArc from './pieChart';
import SitePicker from '../../components/sitePicker';
import axios from 'axios';
import UnknownModal from './unknownModal';

const Validate = () => {
    const [openModal, setOpenModal] = useState(false);
    const [siteMenu, setSiteMenu] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [site, setSite] = useState(null);

    useEffect(() => {
        async function fetchSitesList() {
            const result = await getSiteInfo({ action: 'sites' });
            setSiteMenu(result);
            const defaultSite = await getSiteInfo({ site: result[0] })
            setSite(defaultSite);
        }

        fetchSitesList();
    }, [])

    useEffect(() => {
        async function fetchSite() {
            const result = await getSiteInfo({ site: selectedSite });
            setSite(result);
        }

        fetchSite();
    }, [selectedSite])

    const getSiteInfo = (params) => {
        return axios.get('https://smartsystems-8439f-prod0.azurewebsites.net/api/deviceStatus', {
            params,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_SITE_DEVICE_DB_API_KEY
            },
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log('Validate');
                console.log(error);
            })
    };

    const handleSiteSelect = (node) => {
        setSelectedSite(node);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    }


    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '20px' }}>
                <Box sx={{ width: '80%', backgroundColor: 'yellow', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
                    <Typography variant='body1' sx={{ fontSize: '1.5rem', textAlign: 'center' }}>
                        This Section is Currently Under Development
                    </Typography>
                </Box>
            </Box>


            <Box sx={{ width: '100%', mt: '35px' }}>
                <Stepper step={3} />
            </Box>


            <Box sx={{ position: 'relative', top: '75px', left: '15px', width: '100%', height: '50px', display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h1'>{site ? site.site : ''}</Typography>
            </Box>
            <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ height: '530px', width: '260px', backgroundColor: '#FFFFFF', border: '1px solid #e0e0e0', borderRadius: '4px', overflow: 'hidden', minWidth: '150px', mr: '130px' }}>
                    {siteMenu.length !== 0 ? <SitePicker sites={siteMenu} onNodeSelect={handleSiteSelect} />
                        : <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>}
                </Box>
                <PieActiveArc site={site} />
                <Box sx={{ display: 'flex', gap: 2, alignSelf: 'center', flexDirection: 'column', ml: '20px' }}>
                    {site ?
                        <>
                            <Box sx={{ display: 'flex', width: '260px', height: '100px', backgroundColor: '#00e676', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', flexDirection: 'column', pt: '15px' }}>
                                <Typography color='primary' variant='h1'>{site.online}</Typography>
                                <Typography color='primary' variant='body500'>Online</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '260px', height: '100px', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', flexDirection: 'column', pt: '15px' }}>
                                <Typography color='primary' variant='h1'>{site.offline}</Typography>
                                <Typography color='primary' variant='body500'>Offline</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', width: '260px', height: '100px', backgroundColor: 'orange', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', flexDirection: 'column', pt: '15px' }}>
                                <Typography color='primary' variant='h1'>{site.noStatus}</Typography>
                                <Typography color='primary' variant='body500'>No Status</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', position: 'relative', width: '260px', height: '100px', backgroundColor: '#0098e2', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', flexDirection: 'column', pt: '15px' }}>
                                <Typography color='primary' variant='h1'>{site.unknown}</Typography>
                                <Typography color='primary' variant='body500'>Unknown</Typography>
                                <Box sx={{ position: 'absolute', right: '10px', bottom: '30px' }}>
                                    <IconButton
                                        icon={InfoIcon}
                                        altVariant="staticWhite"
                                        size="xxxxl"
                                        tooltipLabel="More Info"
                                        onClick={() => { setOpenModal(true) }}
                                    />
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            <Skeleton variant="rectangular" width={270} height={100} />
                            <Skeleton variant="rectangular" width={270} height={100} />
                            <Skeleton variant="rectangular" width={270} height={100} />
                            <Skeleton variant="rectangular" width={270} height={100} />
                        </>
                    }
                </Box>

            </Box>
            <UnknownModal open={openModal} onClose={handleCloseModal} unknown={site ? site.unknownList : []}/>
            <StepperButtons navigateBack={'/onboard/create'} nextDisabled={true} />
        </>
    );
}

export default Validate;