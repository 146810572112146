import * as React from 'react';
import Dashboard from './pages/Dashboard';
import Onboard from './pages/Onboard';
import Discover from './pages/Discover';
import Create from './pages/Create';
import Validate from './pages/Validate';
import StatusUpdater from './pages/StatusUpdater';
import { PageLayout } from './components/PageLayout';
import { Route, Routes, useNavigate } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./NavigationClient";

const App = ({ pca }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <Pages />
      </PageLayout>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/status" element={<StatusUpdater />} />
      <Route path="/onboard" element={<Onboard />} />
      <Route path="/onboard/discover" element={<Discover />} />
      <Route path="/onboard/create" element={<Create />} />
      <Route path="/onboard/validate" element={<Validate />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}

export default App