import * as React from 'react';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import formOptions from './formOptions';
import { Banner, TextField, Modal, Box, Typography, Button, CircularProgress, ToggleButton, ToggleButtonGroup } from '@acuity-brands/fusion';
import CheckIcon from '@acuity-brands/fusion/icons/checkmark.js'
import SendIcon from '@acuity-brands/fusion/icons/arrow-right.js'
import Collapse from '@mui/material/Collapse';

const StatusUpdater = () => {
  const [formData, setFormData] = useState(formOptions["nlight"])
  const [openModal, setOpenModal] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState("nlight");

  // handle submit to api to send message to IoT Hub
  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedFormData = formData.map(field =>
      field.value.trim() === '' ? { ...field, error: true } : { ...field, error: false }
    );

    setFormData(updatedFormData);

    if (!updatedFormData.some(field => field.error)) {

      const iot_message = { secretName: formData[0].value, inputs: {} };

      formData.forEach((field) => {
        if (field.id !== 'secretName') {
          iot_message.inputs[field.id] = field.value;
        }
      });

      setLoading(true);

      axios.post(`${process.env.REACT_APP_FUNCTION_APP_URL}/api/keyVault`, iot_message, {
        headers: {
          'Content-Type': 'application/json',
          'x-functions-key': process.env.REACT_APP_KEY_VAULT
        },
      })
        .then(response => {
          setSuccessMessage(response.data);
          setOpenBanner(true);
        })
        .catch(error => {
          setErrorModalMessage(error.message);
          setOpenModal(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // handle updating changes in form fields
  const handleChange = (id, input) => {
    setFormData(prevFormData =>
      prevFormData.map(field =>
        field.id === id ? { ...field, value: input } : field
      )
    );
  };

  const handleToggleChange = (event, newValue) => {
    if (newValue !== null) {
      setSelectedValue(newValue);
      setFormData(formOptions[newValue]);
    }
  };

  // map out form input
  const formInput = ({ label, id, value, error, secret }) => {
    return (
      <FormControl
        fullWidth
        margin="normal"
        key={id}
        onChange={(e) => handleChange(id, e.target.value)}
      >
        <TextField
          label={label}
          value={value}
          type={secret ? 'password' : null}
          error={error}
          secondaryLabel={error ? 'Please fill field.' : null}
        />
      </FormControl>
    )
  };

  return (
    <>
      <Collapse
        in={openBanner}>
        <Banner
          sx={{ margin: '10px' }}
          severity='success'
          title='Success!'
          showIcon
          icon={CheckIcon}
          onClose={() => { setOpenBanner(false) }}
        >
          {successMessage}
        </Banner>
      </Collapse>
      <Modal
        dense
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Error..."
      >
        <Typography>{errorModalMessage}</Typography>
      </Modal>
      <Box
        component="form"
        sx={{
          display: 'flex',
          alignSelf: 'center',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '50em'
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx ={{display: "flex", gap: 2}}>
        <Typography variant='h4'>Status Updater Form</Typography>
        <ToggleButtonGroup
          exclusive
          onChange={handleToggleChange}
          orientation="horizontal"
          value={selectedValue}
        >
          <ToggleButton value="nlight">
            nLight
          </ToggleButton>
          <ToggleButton value="HVAC">
            HVAC
          </ToggleButton>
        </ToggleButtonGroup>
        </Box>
        {Object.keys(formData).map((index) => (formInput(formData[index])))}
        <Box sx={{ mt: "25px" }}>
          <Button
            onClick={handleSubmit}
            endIcon={SendIcon}
          >
            Submit
          </Button>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
}

export default StatusUpdater;
