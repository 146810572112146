import NavBar from "./NavBar";
import { SideMenu, Box } from '@acuity-brands/fusion';
import SignalIcon from '@acuity-brands/fusion/icons/signal.js';
import AppsIcon from '@acuity-brands/fusion/icons/apps.js'
import ReviewIcon from '@acuity-brands/fusion/icons/review.js';
import ValidateTwinsIcon from '@acuity-brands/fusion/icons/data-center';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Login from "./Login";

export const PageLayout = (props) => {
    const navigate = useNavigate();

    const routeMap = [
        {
            icon: AppsIcon,
            id: 0,
            label: 'Dashboard',
            onClick: () => navigate('/'),
            tooltip: 'Dashboard',
        },
        {
            icon: SignalIcon,
            id: 1,
            label: 'Onboard Devices',
            onClick: () => navigate('/onboard'),
            tooltip: 'Onboard Devices',
        },
        {
            icon: ValidateTwinsIcon,
            id: 4,
            label: 'Validate Twins',
            onClick: () => navigate('/onboard/validate'),
            tooltip: 'Validate Twins'
        },
        {
            icon: ReviewIcon,
            id: 3,
            label: 'Status Updater',
            onClick: () => navigate('/status'),
            tooltip: 'Status Updater',
        },
    ]

    return (
        <>
            <NavBar />
            <AuthenticatedTemplate>
                <Box sx={{ display: 'flex' }}>
                    <SideMenu
                        collapsed
                        stacked
                        items={routeMap}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: '65px', ml: '74px' }}>
                        {props.children}
                    </Box>
                </Box>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </>
    );
};