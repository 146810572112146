import { useEffect, useState } from 'react';
import { Modal, DetailsList, Typography } from '@acuity-brands/fusion';

const UnknownModal = ({ open, onClose, unknown }) => {
    const [unknownList, setUnknownList] = useState([])

    useEffect(() => {
        setUnknownList(unknown);
    }, [unknown])

    return (
        <Modal dense width='450px' height='760px' title="Unknown Device List" open={open} onClose={onClose}>
            <DetailsList
                divider
                showMoreCount={5}
                items={[
                    {
                        content: <Typography weight='bold' variant="body500">Reason</Typography>,
                        id: 'id-device',
                        label: <Typography weight='bold' variant="body500">Device</Typography>,

                    },
                    ...Object.entries(unknownList).map(([key, value], index) => ({
                        content: <Typography variant="body200">{value}</Typography>,
                        id: `id-${index}`,
                        label: <Typography variant="body200">{key}</Typography>,
                    }))
                ]}
            />
        </Modal>

    )
}

export default UnknownModal;