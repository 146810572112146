import * as React from 'react';
import { Box, Typography, Checkbox } from '@acuity-brands/fusion';
import Stepper from '../../components/stepper';
import StepperButtons from '../../components/stepperButtons';


const Discover = () => {

  return (
    <>
      
      <Box sx={{ width: '100%', mt: '35px' }}>
        <Stepper step={1} />
      </Box>
      <Box sx={{ width: '100%', flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3}}>
        <Checkbox
          label={<Typography variant='h5'>BACnet Controller​</Typography>}
          onChange={function Ga() { }}
        />
        <Checkbox
          label={<Typography variant='h5'>nLight Controller​</Typography>}
          onChange={function Ga() { }}
        />
        <Checkbox
          label={<Typography variant='h5'>Niagara Supervisor​</Typography>}
          onChange={function Ga() { }}
        />
        <Checkbox
          label={<Typography variant='h5'>Atrius Catalog (remote discovery)​</Typography>}
          onChange={function Ga() { }}
        />
        </Box>
      </Box>
      <StepperButtons navigateBack={'/onboard'} navigateTo={'/onboard/create'} />
    </>
  );
}

export default Discover;
