import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, GlobalStyle } from '@acuity-brands/fusion';
import { LicenseInfo } from '@mui/x-license';
import App from "./App";

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

LicenseInfo.setLicenseKey('4ff8df4db239e99f61b8055c3803e37fTz04NzI1NixFPTE3NDMxMDA2NDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <Router>
      <ThemeProvider>
        <GlobalStyle />
        <App pca={msalInstance} />
      </ThemeProvider>
    </Router>
  );
});
