import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { Box } from '@acuity-brands/fusion';
import { useState, useEffect } from 'react';

export default function PieActiveArc({ site }) {
  const [data, setData] = useState([
    { id: 0, value: 0, label: 'Online', color: '#00e676' },
    { id: 1, value: 0, label: 'Offline', color: 'red' },
    { id: 2, value: 0, label: 'No Status', color: 'orange' },
    { id: 3, value: 0, label: 'Unknown', color: '#0098e2' },
  ]);

  const total = data[0].value + data[1].value + data[2].value + data[3].value;

  useEffect(() => {
    if (site) {
      setData(prevData => {
        const newData = [...prevData];
        newData[0].value = site.online;
        newData[1].value = site.offline;
        newData[2].value = site.noStatus;
        newData[3].value = site.unknown;
        return newData;
      });
    }
  }, [site]);

  const StyledTextHeader = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 50,
  }));

  const StyledTextLabel = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 30,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <>
      <StyledTextHeader x={left + width / 2} y={top + height / 2 - 20}>
        {children}
      </StyledTextHeader>
      <StyledTextLabel x={left + width / 2} y={top + height / 2 + 25}>
        Devices
      </StyledTextLabel>
    </>
    );
  }

  return (
    <Box sx={{ height: '100%', width: '500px' }}>
      <PieChart
        series={[
          {
            data,
            innerRadius: 100,
            outerRadius: 200,
            paddingAngle: 1,
            cornerRadius: 5,
          },
        ]}
        slotProps={{
          legend: { hidden: true },
        }}
      >
        <PieCenterLabel>
          {total}
        </PieCenterLabel>
      </PieChart>
    </Box>
  );
}