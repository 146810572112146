const formOptions = {
    "HVAC": [
        {
            label: "Container ID",
            id: "secretName",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Site Name",
            id: "siteName",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Device IP",
            id: "deviceIp",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Supervisor",
            id: "svPassword",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Controller / Supervisor Username",
            id: "apexUser",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Controller / Supervisor Password",
            id: "apexPassword",
            value: "",
            error: false,
            secret: true
        }
    ],
    "nlight": [
        {
            label: "Container ID",
            id: "secretName",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Site Name",
            id: "siteName",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "nECY IP",
            id: "deviceIp",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "Sensorview Password",
            id: "svPassword",
            value: "",
            error: false,
            secret: true
        },
        {
            label: "nECY Username",
            id: "apexUser",
            value: "",
            error: false,
            secret: false
        },
        {
            label: "nECY Password",
            id: "apexPassword",
            value: "",
            error: false,
            secret: true
        },
    ]
}

export default formOptions;