import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Menu, Box, Icon, Typography, Avatar } from '@acuity-brands/fusion';
import { AccountPicker } from "./AccountPicker";
import LogoutIcon from '@acuity-brands/fusion/icons/logout';
import SwitchIcon from '@acuity-brands/fusion/icons/switch';

export const SignOutButton = () => {
    const { instance } = useMsal();
    const [accountSelectorOpen, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleMenuClose = () => { setAnchorEl(null) };
    const openMenu = Boolean(anchorEl);
    const [initials, setInitials] = useState(null);

    const handleAccountSelection = () => {
        setAnchorEl(null);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };


    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        if (activeAccount) {
            setInitials(activeAccount.name.split(',').map(word => word.trim()[0].toUpperCase()).join(''))
        } else {
            setInitials(null);
        }
    }, [activeAccount]);

    return (
        <Box>
            <Avatar
                onClick={handleMenuClick}
                size="lg"
                variant="initials"
            >
                {initials}
            </Avatar>
            <Menu
                open={openMenu}
                anchorEl={anchorEl}
                items={[
                    {
                        index: 0,
                        label:
                            <>
                                <Box sx={{ mr: 'auto' }}><Typography>Switch Accounts</Typography></Box>
                                <Icon icon={SwitchIcon} color='#0098e2' />
                            </>
                    },
                    {
                        index: 1,
                        label:
                            <>
                                <Box sx={{ mr: 'auto' }}><Typography>Sign Out</Typography></Box>
                                <Icon icon={LogoutIcon} color='#0098e2' />
                            </>
                    }
                ]}
                onClose={handleMenuClose}
                onSelect={(item) => {
                    item.index === 0 ?
                        handleAccountSelection() :
                        instance.logoutRedirect();
                }}
            />
            <AccountPicker open={accountSelectorOpen} onClose={handleClose} />
        </Box>
    )
};